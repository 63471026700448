import React, { forwardRef, useState, useImperativeHandle, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';

interface ProfileInfoProps {
  profileData: UserModel | null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  ref: MutableRefObject<object | undefined>;
}

export interface ProfileInfoRefs {
  setAvatarImg: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const ProfileInfo = forwardRef<ProfileInfoRefs | undefined, ProfileInfoProps>((props, ref) => {
  const { profileData } = props;

  const [fullness] = useState(90);
  const { t } = useTranslation();
  const [avatarImg, setAvatarImg] = useState(profileData?.imgUrl);

  const publicRef = {
    setAvatarImg: setAvatarImg,
  };

  useImperativeHandle(ref, () => {
    return publicRef;
  });

  return profileData ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <BaseAvatar shape="circle" src={avatarImg} alt="Profile" />
      </S.ImgWrapper>
      <S.Title>{`${profileData?.firstName} ${profileData?.lastName}`}</S.Title>
      <S.Subtitle>{profileData?.userName}</S.Subtitle>
      <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper>
      <S.Text>{t('profile.fullness')}</S.Text>
    </S.Wrapper>
  ) : null;
});
