import React from 'react';
import { SetPasswordLinkSentForm } from '@app/components/auth/SecurityCodeForm/SetPasswordLinkSentForm';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const SetPassWordLinkSentPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.passwordLinkSent')}</PageTitle>
      <SetPasswordLinkSentForm />
    </>
  );
};

export default SetPassWordLinkSentPage;
