import React from 'react';
import { ConfirmEmailForm } from '@app/components/auth/NewPasswordForm/ConfirmEmailForm';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const ConfirmEmailPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.confirmEmail')}</PageTitle>
      <ConfirmEmailForm />
    </>
  );
};

export default ConfirmEmailPage;
