/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { CheckboxProps } from 'antd';
import * as S from './BaseCheckbox.styles';
// import {useTranslation} from "react-i18next";
// import {BaseAvatar} from "@app/components/common/BaseAvatar/BaseAvatar";

export type BaseCheckboxProps = CheckboxProps;

// interface BaseCheckboxInterface extends React.FC<BaseCheckboxProps> {
//   Group: typeof S.CheckboxGroup;
// }

interface BaseCheckboxInterface
  extends React.ForwardRefExoticComponent<BaseCheckboxProps & React.RefAttributes<HTMLInputElement | null>> {
  Group: typeof S.CheckboxGroup;
}

// export const BaseCheckbox = forwardRef<object | undefined, BaseCheckboxProps>((props, ref) => {
//   const publicRef = {};
//
//   useImperativeHandle(ref, () => {
//     return publicRef;
//   });
//
//   const BaseCheckboxI: BaseCheckboxInterface = (props) => {
//     return <S.Checkbox {...props} />;
//   };
//   BaseCheckboxI.Group = S.CheckboxGroup;
//
//   return <BaseCheckboxI {...props} />;
// });

export const BaseCheckbox = React.forwardRef<HTMLInputElement | null, BaseCheckboxProps>((props, ref) => (
  <S.Checkbox ref={ref} {...props} />
)) as BaseCheckboxInterface;

// export const BaseCheckbox: BaseCheckboxInterface = (props) => {
//   return <S.Checkbox {...props} />;
// };

BaseCheckbox.Group = S.CheckboxGroup;
