import { httpApi } from '@app/api/http.api';
//import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface profileRequest {
  firstName: string;
  lastName: string;
  nickname: string;
  sex: string;
  birthday: string;
  language: string;
  phone: string;
  email: string;
  country: string;
  city: string;
  address1: string;
  address2: string;
  zipcode: string;
  website: string;
  twitter: string;
  linkedin: string;
  facebook: string;
}

export interface passwordChangeRequest {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
  token: string;
}

export interface ConfirmEmailData {
  token: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export interface ProfileResponse {
  user: UserModel;
  message: string;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('api/users/login', { ...loginPayload }).then(({ data }) => data);

export const logout = (): Promise<undefined> =>
  httpApi.post<undefined>('api/users/logout', {}).then(({ data }) => data);

export const profile = (profilePayload: profileRequest): Promise<ProfileResponse | undefined> =>
  httpApi.post<undefined>('api/users/user-profile', { ...profilePayload }).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('api/users/register', { ...signUpData }).then(({ data }) => data);

export const passwordChange = (passwordChangePayload: passwordChangeRequest): Promise<undefined> =>
  httpApi.post<undefined>('api/users/password-change', { ...passwordChangePayload }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('/api/users/forgot-password', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('/api/users/set-new-password', { ...newPasswordData }).then(({ data }) => data);

export const confirmEmail = (confirmEmailData: ConfirmEmailData): Promise<undefined> =>
  httpApi.post<undefined>('/api/users/confirm-email', { ...confirmEmailData }).then(({ data }) => data);
