import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SecurityCodeForm.styles';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';

export const SetPasswordLinkSentForm: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <Auth.BackWrapper onClick={navigateBack}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <S.ContentWrapper>
          <S.ImageWrapper>
            <BaseImage src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t('setPasswordLinkSentForm.title')}</Auth.FormTitle>
          <S.VerifyEmailDescription>{t('common.resetPasswordLinkSent')}</S.VerifyEmailDescription>
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
